:root {
  --primary-active-text: #40a9ff;
  --primary-darker: #00346b;
  --primary-pop-one-fifth-opacity: rgba(24, 144, 255, 0.2);

  --secondary-selected-base: #8d47cb;
  --secondary-selected-darker: #6d26ab;

  --secondary-base: #7566e8;
  --secondary-hover: #5244c6;
  --secondary-dark: #443d82;
  --secondary-grid-background: #352f69;
  --secondary-darker: #262057;
  --secondary-darkest: #171242;

  --background-fill: #f8fbfd;

  --gold-light: #ffe490;
  --gold-border: #e8c353;
  --gold-base: #ffd555;
  --gold-dark: #b98b00;
  --gold-tired-sand: #776520;
  --gold-opaque-fill: rgba(255, 213, 24, 0.08);

  --error-frat-pants: #ff7875;
  --error-beer-stain: rgba(255, 77, 79, 0.2);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.simulation-table-row {
  background-color: #fff;
}

.simulation-date-column {
  color: #aaa;
}

.ant-table-body {
  border: 1px solid #e8e8e8;
  border-bottom: none;
}

.flex-column-stretch {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.ant-menu-dark.ant-menu-dark.ant-menu-dark-purple:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: var(--secondary-dark);
  border-right: 3px solid #ffffff;
}

/*** React Data Grid Modern RDG ***/

.rdg {
  border-color: #e5e7eb;
  border-width: 0px;
  border-top-width: 1px;
}

.rdg-cell {
  text-align: center;
  color: #3c3c3c;
  border-color: #e5e7eb;
}

.rdg-header-cell {
  color: #6b7280;
}

/*** React Datasheet ***/
.data-grid-container .data-grid .cell.read-only {
  background: var(--background-fill);
  font-weight: 300;
}

.data-grid-container .data-grid .cell {
  border-color: #e6e8ea;
}

/**** Dark Grid Panel ****/

.dark-grid-panel .react-grid-Canvas,
.dark-grid-panel .react-grid-Cell {
  background-color: var(--secondary-grid-background);
  font-weight: 300;
}

.dark-grid-panel .react-grid-Cell {
  background-color: transparent;
  border-color: var(--secondary-darkest);
  color: #fff;
}

.dark-grid-panel .react-grid-Row:hover .react-grid-Cell,
.dark-grid-panel .react-grid-Row.row-context-menu .react-grid-Cell {
  background-color: var(--secondary-darker);
}

.dark-grid-panel .react-grid-HeaderCell {
  background: var(--secondary-darker);
  border-color: var(--secondary-darkest);
  color: #fff;
}

.dark-grid-panel .react-grid-Grid,
.dark-grid-panel .react-grid-Main {
  border-color: var(--secondary-darkest);
  outline-color: var(--secondary-darkest) !important;
}

/**** Project Data Table ****/

.project-data-table .ant-table-body {
  border-left: none;
}

div.ant-table-wrapper.project-data-table
  div.ant-spin-nested-loading
  div.ant-spin-container
  div.ant-table.ant-table-small.ant-table-bordered.ant-table-ping-left.ant-table-ping-right.ant-table-fixed-header
  div.ant-table-container
  div.ant-table-header
  table {
  visibility: visible !important;
}

div.ant-table-wrapper.project-data-table
  div.ant-spin-nested-loading
  div.ant-spin-container
  div.ant-table-container
  div.ant-table-header
  table {
  visibility: visible !important;
}

th:last-child {
  border-right: 2px solid #f0f0f0 !important;
}

.virtual-table-wrapper {
  background-color: #fbfbfb;
}

.virtual-table-fixed-left {
  position: sticky !important;
  left: 0px !important;
  z-index: 2;
}

.project-data-table .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.project-data-table .virtual-table-cell {
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-data-table .virtual-table-cell.hovered-cell {
  background: #fbfbfb;
}

.project-data-table .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

/** Pipeline **/
.pipeline-spin-wrapper {
  height: 100vh;
  width: 100vw;
}

/*** Radio Group ***/

.yellow-radio-group .ant-radio-button-wrapper {
  color: var(--secondary-hover);
}

.yellow-radio-group.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--secondary-dark);
  background: var(--gold-base);
  border-color: var(--gold-border);
}

.yellow-radio-group .ant-radio-button-wrapper:hover {
  color: var(--gold-dark);
}

.yellow-radio-group .ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px var(--gold-opaque-fill);
}

.yellow-radio-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--gold-border);
}

/******** Demo Call Uploader ********/

.demo-call-uploader .ant-upload-list {
  color: #fff;
  text-align: left;
}

.demo-call-uploader .anticon {
  color: #fff !important;
}

.demo-call-uploader .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: var(--primary-darker);
}

/******** Ant Modal *********/
.ant-modal-root {
  z-index: 21474836 !important;
  position: relative;
}

/*** Button ***/
/* Yellow Button */
.btn-yellow {
  color: var(--secondary-dark);
  background-color: var(--gold-base);
  border-color: var(--gold-border);
  text-shadow: none;
}

.btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus {
  background-color: var(--gold-light);
  color: var(--secondary-dark);
  border-color: var(--gold-border);
}

/* Purple Button */
.btn-purple {
  color: #ffffff;
  background-color: var(--secondary-base);
  border-color: var(--secondary-hover);
  text-shadow: none;
}

.btn-purple:hover,
.btn-purple:active,
.btn-purple:focus {
  background-color: var(--secondary-base);
  color: #ffffff;
  border-color: var(--secondary-darker);
}

/* Purple Button */
.btn-bright-purple {
  color: #ffffff;
  background-color: var(--secondary-selected-darker);
  border-color: var(--secondary-selected-darker);
  text-shadow: none;
  border-radius: 4px;
}

.btn-bright-purple:hover,
.btn-bright-purple:active,
.btn-bright-purple:focus {
  background-color: var(--secondary-selected-base);
  color: #ffffff;
  border-color: var(--secondary-selected-darker);
}

/*** Stripe Checkout Element Input ***/
.stripe-card-input {
  padding: 7px 5px;
}

.StripeElement--focus {
  border-color: var(--primary-active-text);
  box-shadow: 0 0 0 2px var(--primary-pop-one-fifth-opacity);
}

.StripeElement--invalid,
.StripeElement--invalid:hover {
  border-color: var(--error-frat-pants);
  box-shadow: 0 0 0 2px var(--error-beer-stain);
}

/*** Data App Context ***/

.data-app-context-loading-wrapper {
  display: flex;
  flex: 1;
}

.data-app-context-loading-wrapper > .ant-spin-container {
  display: flex;
  flex: 1;
}

/** Hide plotly icon/link in chart **/
.modebar-btn.plotlyjsicon.modebar-btn--logo {
  display: none;
}

/*** AntD Picker Dropdown ***/

.ant-picker-dropdown {
  z-index: 21474900;
}

/* antd Tooltip */
.ant-tooltip.feature-flag-gate-tooltip-overlay .ant-tooltip-inner {
  background-color: white;
  padding: 0;
  margin: 0;
}

/**** AntD Message Custom ****/

.ant-message {
  z-index: 21474950;
}

.refresh-message > .ant-message-notice-content {
  border-radius: 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  padding: 6px 16px;
  font-weight: 300;
}

/**** AntD Result *****/

.ant-result-subtitle {
  font-size: 16px;
}
